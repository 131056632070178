import React from "react"
import {useTranslation} from "react-i18next"
import {
    Box,
    Container,
    Heading,
    Paragraph,
} from "vactory-ui"


const NotFoundPage = (props) => {
    const {t} = useTranslation();

    return (
        <Container>
            <Box textAlign="center" p="large">
                <Heading level={3}>Oups :(</Heading>
                <Paragraph as="p">{t('It seems that this page does not exist ...')}..</Paragraph>
            </Box>
        </Container>
    )
}

export default NotFoundPage
